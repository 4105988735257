@import url(https://db.onlinewebfonts.com/c/b6a82233a2e528638ee7c5aedc864270?family=Adobe+Clean+Serif);
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

.App {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

h1.heading {
    text-align: left;
    font-size: 30px;
    margin: 10px auto 0px;
    color: #0a0b0d;
    font-weight: 700;
    font-family: 'DM Serif Display', serif;
  }

  h2.heading {
    text-align: left;
    font-size: 26px;
    margin: 20px auto;
    color: #0a0b0d;
    font-family: 'DM Serif Display', serif;
    font-weight: 600;
  }

  .button_common, .button_common:hover, .button_common:active {
    background-color: #f2ba3f !important;
    text-decoration: none;
    color: #0a0b0d;
    padding: 9px 50px;
    border-radius: 30px 30px;
    -webkit-border-radius: 30px 30p;
    -moz-border-radius: 30px 30p;
    -ms-border-radius: 30px 30p;
    -o-border-radius: 30px 30p;
    border: none;
    outline: none;
    font-weight: 600;
  }

/* 
.landing-img-container {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-form-container {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-form {
    padding: 10px 10px;
}

.common-container {
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
    padding: 0px 10px;

    .inner-container {
        background-color: #E5DFD7;
        border-radius: 15px;
        padding: 10px;
        width: 100%;
        margin: 10px 0;
        max-width: 500px;
    }
}

.slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.time-picker-button {
    background-color: #FFF9F1;
    padding: 14px 15px;
    border-radius: 20px;
    font-size: 14px;
    border: none;
    margin: 5px 0px;
}

.active-t {
    background-color: rgb(242, 186, 63);
    color: #FFFF;
}

.days-input {
    width: 50%;
    height: 45px;
    border: none;
    border-radius: 20px;
    background: rgb(255, 249, 241);
    padding-left: 34px;
    font-size: 20px;
    font-weight: bold;
}

.days-input:focus {
    outline: none;
    border: none;
}

.days-placeholder {
    position: absolute;
    right: 28%;
    top: 13px;
    color: rgb(19, 80, 73);
}

.day-input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calender-container {
    display: flex;
    align-items: center;
    flex-direction: column;

}

.days-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.days-lbl {
    font-size: 14px;
    padding: 5px 5px;
}

.days-active {
    background-color: rgb(242, 186, 63);
    color: #FFFF;
    border-radius: 6px;
}

.time-pick {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    flex-wrap: wrap;
}

.time-picker-inp {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 20px;
    background: rgb(255, 249, 241);
    font-size: 16px;
    font-weight: 500;
    padding: 10px 40px;
}

.time-picker-inp:focus {
    outline: none;
    border: none;
}

.commmon-heading {
    font-size: 12px;
}

.nav_btn {
    max-width: 500px;
    font-size: 14px;
}

.cal-header {
    width: 100%;
    max-width: 500px;
    background-color: #E5DFD7;
    height: 62px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 0px 0px 25px 25px;


}

.header-lbl {
    text-align: center;
    width: 90%;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.backarrow {
    width: 20px;
}

.rylife-log {
    display: none;
    position: absolute;
    left: 30%;
    top: 10%;
}

@media only screen and (min-width: 768px) {
    .rylife-log {
        display: block;
        position: absolute;
        left: 9%;
        top: 36%;
        width: 22%;
    }

    .calender-container {
        padding-left: 30%;
    }
}

.time-select {
    height: 42px;
    width: 160px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 19px;
    padding: 0px 17px;
    border: none;
    background: #FFF9F1;
}

.land-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.logo-container {
    width: 100%;    
    align-items: center;
    justify-content: center;
    height: 40%;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.regi-cont {
    height: 100%;
}

.land-logo {
    display: none;
}

.side-menu-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    border-radius: 46px;
    padding: 10px;
    overflow: hidden;
}

.side-manu-item {
    color: white;
    font-size: 12 px;
    font-weight: bold;
    display: flex;
    align-items: center;
    background-color: rgb(19, 80, 73);
    padding: 18px 22px;
}

.side-manu-item img {
    height: 20px;
    width: 20px;
    margin-right: 15px;
}

@media only screen and (min-width: 768px) {

    .land-container {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .logo-container {
        width: 50%;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .login-container {
        width: 50%;
        justify-content: space-around;
        align-items: center;

    }

    .land-logo {
        display: flex;
    }


}


.gender-select {
    height: 31px;
    width: 100%;
    font-size: 9px;
    font-weight: 500;
    border-radius: 19px;
    padding: 0px 17px;
    border: none;
    background: #FFF9F1;
}


.common-tabs {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    justify-content: space-between;
    border-bottom: #E5DFD7 1px solid;
    padding-bottom: 0px;
}

.common-tab-item {
    display: flex;
    align-items: center;
    font-size: 13px;
}

.common-sec-btn {
    background-color: transparent;
    border: 0.7px solid rgb(242, 186, 63);
    padding: 7px;
    position: absolute;
    right: 7px;
    border-radius: 20px;
    font-size: 12px;
    top: 4px;
}

.w-half {
    width: 50%;
}

.settings-menu {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #DEE6E1;
    padding: 10px 5px;
    position: relative;
}

.pop-up-head-containter {
    position: absolute;
    top: 7%;
    background: #135049;
    width: 90%;
    height: 40px;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 20px;
    z-index: 0;
    color: white;
}

.client-lbl1-ref {
    font-size: 15px;
    font-weight: 500;
}

.add-ref {
    position: absolute;
    background: #135049;
    right: 17px;
    height: 28px;
    top: 6px;
    border-radius: 50%;
    padding: 6px;
}

.client-item {
    position: relative;
}

.client-item p {
    font-size: 14px;
    border-bottom: 1px solid #ECE9E5;
    padding-bottom: 13px;
}

.check_box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
}

.checkContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    right: 1%;
    bottom: 13px;
}

.check_mark {
    position: absolute;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 15px;
    top: -18px;
}

.search_ref {
    width: 90%;
    height: 39px;
    border-radius: 20px;
    padding: 10px 33px;
    font-size: 12px;
    border: none;
    outline: none;
}

.search_ref_icon {
    height: 20px;
    position: absolute;
    top: 10px;
    left: 8%;
}

.digital-note-cont {
    display: flex;
    flex-direction: column;

}

.digital-note-cont {
    padding: 10px 25px;
    background-color: #E5DFD7;
    border-radius: 15px;
}

.response-cont {
    padding: 10px 25px;
    background-color: #4F7373;
    border-radius: 15px;
    color: white;
}

.respo-head-lbl {
    font-size: 16px;
    margin: 4px 10px;
}

.respo_inp {
    width: 100%;
    height: 80px;
    background: #FDF7EF;
    border-radius: 15px;
    border: none;
    padding: 11px;
    font-size: 13px;
}

.btn_pr {
    width: 90%;
    margin-left: 5%;
    background-color: #F2BA3F;
    border-radius: 15px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border: none;
}

.session-inp-text {
    background: #E5DFD7;
    font-size: 15px;
    border: none;
    border-radius: 15px;
    padding: 15px;
    outline: none;
}

.session-btns {
    font-size: 14px;
    padding: 18px 15px;
    border-radius: 25px;
    outline: none;
    border: none;
    width: 195px;
}

.sv-btn {
    background-color: #F2BA3F;
    color: white;
    font-weight: 500;


}

.sv-inp-btn {
    background-color: #FFF9F1;
    color: black;
    border: 1px solid #F2BA3F;
    font-weight: 500;
}

.log-btn {
    position: absolute;
    bottom: 8%;
    left: 0%;
    border: none;
    height: 54px;
    width: 101px;
    font-size: 14px;
    background: transparent;
    font-weight: bold;
}

.calling-wind {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.call-nm {
    font-size: 20px;
    font-weight: bold;

}

.call-btn {
    padding: 27px 20px;
    border-radius: 50%;
    background: #F2BA3F;
    border: none;
    outline: none;
}

.add-session-btns {
    padding: 13px 12px;
    border-radius: 50%;
    background: #135049;
    border: none;
    outline: none;
}

.p-header {
    font-size: 14px;
    font-weight: 500;
}

.let-lbl {
    font-size: 13px;
    width: 161px;
    text-align: center;
    font-weight: 500;
} */



/*------------------ Header CSS---------------- */

.icon-div {
    background: #ccc;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    text-align: center;
    padding: 3px 0;
    margin-right: 0px !important;
}

.account-dropdown .dropdown-menu.show {
    padding: 10px;
    min-width: 115px;
    color: #fff;
    height: auto;
    text-align: right;
    margin-top: 5px !important;
    font-size: 14px;
}

.navbar-expand-lg .dropdown-menu {
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background: rgba(0, 0, 0, 0.5) !important;
}

.navbar-expand-lg .dropdown button, .navbar-expand-lg .dropdown button:active {
    background: transparent;
    border: 0;
    padding: 0;
    padding-right: 10px;
}

.navbar-expand-lg .dropdown button::after {
    display: none;
}