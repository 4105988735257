@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./common";

/* Gloock Font */
@import url("https://fonts.googleapis.com/css2?family=Gloock&display=swap");

:root {
  --theme-color-primary: #050908;
  --theme-color-secondary: #ffffff;
  --custom-color-1: #0c1412;
  --custom-color-2: #141f19;
  --custom-color-3: #676e6a;
  --custom-color-4: #707070;
  --custom-color-5: #939393;
  --custom-color-6: #e06900;
  --custom-color-7: #363636;
  --custom-color-8: #151515;

  --theme-font-family-sora: "Sora", sans-serif;
  --theme-font-family-montserrat: "Montserrat", sans-serif;

  --theme-default-font-size: 14px;
}

body {
  font-family: var(--theme-font-family-montserrat);
  /* font-size: var(--theme-default-font-size); */
  font-size: var(--theme-default-font-size);
  font-weight: 500;
  background-image: url("../images/background/bg-image.jpg");
}

.cursor-pointer {
  cursor: pointer;
}

.row-main {
  min-height: 100vh;
}

.cols-main {
  padding-top: 40px;
}

.border-right-custom {
  border-right: 1px solid var(--custom-color-7);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--theme-font-family-sora);
}

h1 {
  font-size: 2rem;
  /* font-size: 32px; */
}

h2 {
  font-size: 1.5rem;
  /* font-size: 24px; */
}

h3 {
  font-size: 1.17rem;
  /* font-size: 18px; */
}

h4 {
  font-size: 1rem;
  /* font-size: 16px; */
}

h5 {
  font-size: 0.83rem;
  /* font-size: 14px; */
}

h6 {
  font-size: 0.67em;
  /* font-size: 12px; */
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text-color-primary {
  color: var(--theme-color-primary);
}

.text-color-secondary {
  color: var(--theme-color-secondary);
}

.text-color-3 {
  color: var(--custom-color-4);
}

.text-color-orange {
  color: var(--custom-color-6);
}

.orange-small-link {
  color: var(--custom-color-6);
  font-size: 12px;
}

.background-color-primary {
  background-color: var(--theme-color-primary);
}

.border-color-primary {
  border-color: var(--theme-color-primary);
}

.background-color-secondary {
  background-color: var(--theme-color-secondary);
}

.border-color-secondary {
  border-color: var(--theme-color-secondary);
}

.custom-button-primary {
  background: var(--custom-color-2) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  color: var(--custom-color-3);
  font-weight: 600;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
/*register login org admin  GEETA */
input[type="date"] {
  width: 100%;
  border-radius: 50px;
  padding: 9px 15px;
  background-color: #fff9f1;
  outline: none;
  border: none;
}

input[type="date"]::placeholder {
  padding-left: 0px;
  margin-left: 10px;
}

input[type="email"] {
  width: 100%;
  border-radius: 50px;
  padding: 9px 15px;
  background-color: #fff9f1;
  outline: none;
  border: none;
}

input[type="tel"] {
  width: 100%;
  border-radius: 50px;
  padding: 9px 15px;
  background-color: #fff9f1;
  outline: none;
  border: none;
}

input[type="email"]::placeholder {
  padding-left: 0px;
  margin-left: 10px;
}

input[type="text"] {
  width: 100%;
  border-radius: 50px;
  padding: 9px 15px;
  background-color: #fff9f1;
  outline: none;
  border: none;
}

input[type="password"] {
  width: 100%;
  border-radius: 50px;
  padding: 9px 15px;
  background-color: #fff9f1;
  outline: none;
  border: none;
}
select {
  width: 100%;
  border-radius: 50px;
  padding: 9px 15px;
  background-color: #fff9f1;
  outline: none;
  border: none;
}
input[type="text"]::placeholder {
  padding-left: 0px;
  margin-left: 10px;
}

input[type="number"], textarea, select.form-select {
  width: 100%;
  border-radius: 50px;
  padding: 9px 15px;
  background-color: #fff9f1;
  outline: none;
  border: none;
}

textarea {
  border-radius: 20px;
}
input[type="number"]::placeholder {
  padding-left: 0px;
  margin-left: 10px;
}

/* login  CSS*/

.subheading {
  text-align: left;
  font-size: 30px;
  color: #f2bb3f;
  font-weight: 700;
  font-family: "DM Serif Display", serif;
  margin-bottom: 5px;
}
.register {
  background: rgba(192, 192, 192, 0.4);
  border: 0;
  border-radius: 25px;
  max-width: 500px;
}
.register-text {
  text-align: center;
  margin: 5px auto;
  width: 100%;
  display: inline-block;
  font-weight: 400;
  color: #424242;
  text-decoration: underline !important;
}

/* Forgot Password CSS */
.heading.left-arrow svg.bi.bi-chevron-left {
  color: #0a0b0d;
}

/* verify OTP Css */

.otp-input {
  width: 55px !important;
  /* height: 55px; */
  margin: 0 10px;
  border: 2px solid #fff;
  border-radius: 5px !important;
  font-size: 24px;
  text-align: center;
  outline: none;
}

/* New Password CSS */

.closeBtn {
  right: 0;
  top: 0;
  font-size: 20px;
  font-weight: 700;
  color: #0a0b0d;
  text-decoration: none;
}

/* Select Plan CSS */

.selectPlan {
  box-shadow: 0px 0px 20px 2px #cfcfcf;
}

.selectPlan:hover {
  box-shadow: 0px 0px 20px 2px #ff9f10;
  cursor: pointer;
}

.selectPlanActive {
  box-shadow: 0px 0px 20px 2px #ff9f10;
}

.planName {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(52, 27, 27) 0%,
    rgba(200, 113, 59, 1) 50%,
    rgba(227, 172, 63, 1) 100%
  );
}
.planName2 {
  background: rgb(15, 15, 15);
  background: linear-gradient(
    0deg,
    rgba(15, 15, 15, 1) 0%,
    rgba(27, 27, 27, 1) 50%,
    rgba(35, 35, 35, 1) 100%
  );
}
.planName3 {
  background: rgb(236, 230, 223);
  background: linear-gradient(
    0deg,
    rgba(236, 230, 223, 1) 0%,
    rgba(250, 244, 236, 1) 50%,
    rgba(253, 248, 239, 1) 100%
  );
}

.bi-check-lg {
  font-size: 18px;
  color: green;
}
.bi-x-lg {
  font-size: 15px;
  color: red;
}

/* ///////////////css by geeta///////////////// */
/* side barcss */

#container {
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: #fff9f1;
}

#menuOption {
  margin-top: 20;
  color: #135049;
  padding: 10px 20px;
  border-radius: 5px;
}

#menuOption:hover {
  margin-top: 20;
  background-color: #f2ba3f;
  padding: 10px 20px;
  border-radius: 5px;
}

#menutitle {
  /* background-color: red; */
  padding: 50px 10px;
  flex: 1;
  height: 30px;
}
#titleStyle {
  font-size: 22px;
  font-weight: bold;
  font-family: "Gloock", serif;
}
.gloock-font {
  font-family: "Gloock", serif;
}
#header {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#headername {
  color: #125049;
}

.sidebar {
  background-color: rgba(0, 0, 0, 0.1);
  /* padding-left: 20px; */
  padding-bottom: 40px;
  height: calc(100vh - 20px);
  width: 280px;
  margin: 10px 0px 10px 10px;
  border-radius: 20px;
}

.modal1 {
  background-color: "red";
  color: yellow;
}

#headerIconGroup {
  display: flex;
  flex-direction: row;
}
#headerIcon {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  margin-left: 10px;
  cursor: pointer;
}

ol,
.list-group ul {
  padding-left: 0;
}

#sidebar-wrapper .dropdown-menu-list .d-icon {
  max-width: 20px;
  margin: 0 10px;
  /* margin-right: 10px; */
  margin-bottom: 10px;
}

.logo {
  max-width: 200px;
  text-align: left;
  /* margin: -22px 0; */
}

/* ////header css geeta */
.header svg.icon.icon-lg {
  width: 27px;
  margin: 5px;
}

li.notification-dropdown .dropdown-menu.show {
  background: #898989 !important;
  padding: 5px;
  height: auto;
  margin-top: 50px;
}

li.notification-dropdown .dropdown-menu.show a {
  padding: 0px;
  margin-bottom: 10px;
  /* background: !important; */
}
li.notification-dropdown .dropdown-menu.show,
li.account-dropdown .dropdown-menu.show {
  margin-top: 5px;
  border-radius: 15px;
}

.icon-div {
  background: #ccc;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  text-align: center;
  padding: 3px 0;
  margin-right: 10px;
}

.badge-text {
  text-align: center;
  background: #fff;
  padding: 4px;
  font-size: 13px;
  width: 103px;
  border-radius: 81px;
  display: inline-block;
}

.preview-item-joined {
  background: #fff;
  padding: 10px;
  border-left: 2px solid #f2bb3f;
}

.profile .card-body {
  background: #fff;
  border-radius: 20px;
  padding: 5px;
}
.h-dashboard nav.navbar {
  border-radius: 20px;
  margin: 10px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 60px;
  background: rgba(192, 192, 192, 0.4);
}

.header svg.icon.icon-lg {
  /* width: 22px; */
  margin: 7px;
}

.App form label,
.App a,
small-heading {
  text-align: left;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
h2.heading {
  text-align: left;
  font-size: 26px;
  margin: 20px auto;
  color: #3d3c3a;
  font-weight: 600;
}
.h-dashboard h2.heading {
  margin-left: 20px;
  color: #125049;
}
.h-dashboard .dropdown button,
.h-dashboard .dropdown .btn-primary {
  background: transparent;
  border: 0;
  padding: 0;
  padding-right: 10px;
}
img.User {
  max-width: 17px;
  margin: 0 10px;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

#conentContainer {
  background-color: #e5dfd7;
  margin: 10px 20px;
  width: 50%;
  height: 100%;
  padding: 10px 20px;
  border-radius: 10px;
}

/* ====================calender css========================= */
.react-calendar {
  max-width: 100%;
  background: transparent;
  /* border: 1px solid #a0a096; */
  /* font-family: Arial, Helvetica, sans-serif; */
  line-height: 1.125em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  margin: 30px 0px;
  width: 100%;
  padding: 20px 0px;
  align-items: center;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  background-color: #f2ba3f;
  border-radius: 20px;
  padding: 10px;
  margin: 10px 0px;
}
.react-calendar__navigation button {
  min-width: 55px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekdays__weekday abbr {
  padding: 0.5em;
  text-decoration: none;
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 3em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  font-weight: bold;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  border-radius: 5px;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  font-weight: bold;
}
.react-calendar__tile--now {
  background: #ffff76;
  border-radius: 5px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
  border-radius: 5px;
}
.react-calendar__tile--hasActive {
  background: #76baff;
  border-radius: 5px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
  border-radius: 5px;
}
.react-calendar__tile--active {
  background: #f2ba3f;
  border-radius: 5px;
  /* color: white; */
  font-weight: bold;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
  border-radius: 5px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
  border-radius: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*Profile Page CSS*/
.formIcons {
  font-size: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  top: 41px;
  right: 25px;
}

.formIcons i {
  color: #c9c4c4;
}


/*--------------------- Profile Image CSS----------------- */

.edit-profile-page {
  overflow: hidden;
}

.edit-profile-page.custom-drag-box {
  width: 100%;
  margin: auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #999;
  display: inline-flex;
  transition: transform 0.3s;
  padding: 0px;
  height: 110px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  max-width: 110px;
  border-radius: 50%;

  margin-top: -30px;
  border: 2px solid #e3e3e3;
  background-color: #e7e7e7;
}

.edit-profile-image .edit-icon {
  /* left: 110px; */
  z-index: 1;
  /* top: 50px; */
}

.form-upload-photo-preview2 {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}



.edit-profile-page.custom-drag-box img {
  width: fit-content;
  height: -webkit-fill-available;
  border-radius: 0px;
}

@media (max-width: 991px) {
  .edit-profile-page .custom-drag-box {
    max-width: 110px;
    margin: 0;
  }
}

.edit-profile-page.custom-drag-box input[type="file"] {
  position: absolute;
  height: 100%;
  width: 110px;
  opacity: 0;
  top: 0;
  left: 0;
}

.edit-profile-page.custom-drag-box .edit-icon {
  right: 4px;
  bottom: 4px;
}

.employee-data-v-height{
  height: calc(100vh - 238px) !important;
}

.employee-data-v-height::-webkit-scrollbar{
  display: none;
}


/* --------------------- nav tabs css ---------------- */


.sessions-tabs .nav.nav-tabs {
  border: 0 !important;
}

.sessions-tabs .nav-tabs .nav-link {
  background: transparent !important;
  border: 0 !important;
  color: #828583;
  display: inline-block;
}

.sessions-tabs li.nav-item {
  border-bottom: 1px solid #ccc;
  min-width: 140px;
  text-align: center;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-bottom: 2px solid #f2bb3f !important;
  min-width: 130px;
  border-radius: 0;
  font-weight: bold;
  color: #125049 !important;
}


/*------------------ organization page css---------------- */
.organization-details-v-height {
  height: calc(100vh - 184px);
}

.organizations-list table thead tr th,
.organizations-list table tbody tr td {
  padding: 1rem !important;
  font-size: 14px;
  vertical-align: middle;
}

.organizations-list table thead tr th:nth-child(1) {
  border-radius: 10px 0 0 0;
}

.organizations-list table thead tr th:last-child {
  border-radius: 0 10px 0 0;
}

.organizations-list table tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.organizations-list table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.organizations-list table tbody tr:last-child {
  border-bottom: transparent;
}

.organizations-list table tbody tr td {
  padding: 0.7rem 1rem !important;
  vertical-align: middle;
}

.organizations-list .table-striped>tbody>tr:nth-of-type(odd)>* {
  background: rgba(248, 248, 248, 0.4);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.organizations-list {
  height: calc(100vh - 327px);
  overflow: hidden;
  overflow-y: auto;
  border-radius: 20px;
}

.organizations-list::-webkit-scrollbar {
  display: none;
}


/* Employee Data View Details page css */


.profile-wrap {
  background: rgba(192, 192, 192, 0.4) !important;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 20px;
}


/*---------- Employee page css ------------ */

.employee-box {
  cursor: pointer;
  padding: 15px;
  border: 0;
  background: rgba(192, 192, 192, 0.4);
  min-width: 127px;
  text-align: center;
  color: #205a53;
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;

  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.employee-box-inner {
  cursor: pointer;
  border: 0;
  min-width: 127px;
  text-align: center;
  color: #205a53;
  display: flex;
  align-items: center;
  max-height: 65px;
  border-radius: 50px;
  justify-content: space-between;
  font-size: 14px;
}

.employee-box-inner .active-badge .bg-success {
  background-color: #98c149 !important;
  height: 25px;
  line-height: 18px;
}

.employee-box-inner .active-badge .bg-secondary {
  background-color: #a9aeb2 !important;
  height: 25px;
  line-height: 18px;
}

.approval-employee .employee-box-inner-first {
  background-color: #fff9f1;
  border-radius: 20px;
  max-height: initial;
}

.small-text {
  font-size: 12px;
  color: #205a53;
}

.approval-employee button {
  margin-right: 0px !important;
  max-width: none !important;
}

.approve-btn {
  background-color: #f2bb3f !important;
}

.view-profile-btn {
  max-width: 170px;
}

.employee-dropdown .icon-div {
  line-height: 33px;
  font-size: 21px;
}

.employee-dropdown .dropdown-menu.show {
  padding: 10px;
  min-width: 115px;
  color: #fff;
  height: auto;
  text-align: left;

  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.employee-dropdown .dropdown-menu.show a {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  background: transparent;
}

.employee-dropdown .dropdown-menu.show a:hover {
  color: #f2bb3f;
}

.profile-image-box {
  height: 110px;
  width: 110px;
  background-color: #e7e7e7;
  border-radius: 50%;
  margin-top: -30px;
  border: 2px solid #e3e3e3;
  overflow: hidden;
}

.profile-image-box img {
  width: max-content;
  height: -webkit-fill-available;
  
}


.pagination {
  margin-top: 5px;
  margin-bottom: 10px;
}

.pagination .page-item .page-link {
  background-color: #ffffff !important;
}

.pagination .page-item.active .page-link {
  background-color: #f2bb3f !important;
  background: #f2bb3f !important;
  border-color: #f2bb3f !important;
  height: 34px;
}


.employee-data-details-v-height{
  height: calc(100vh - 135px);
}

.my-task-add {
  background-color: #135049 !important;
  color: #ffffff;
}

.my-task-add::placeholder {
  color: #ffffff;
}

.ReactModal__Overlay {
  z-index: 9999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: rgba(210, 210, 210, 0.2) !important;
}

.close-btn {
  border-radius: 50%;
  position: absolute;
  right: 30px;
  top: 20px;
  border: none;
  height: 25px;
  width: 25px;
  transform: rotateZ(44deg);
  background-color: transparent;
}

.loader_orange {
  margin-top: 30px;
  margin: auto !important;
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l4 1s infinite linear alternate;
}

@keyframes l4 {
  0% {
    box-shadow: 20px 0 #f1ab57, -20px 0 rgba(255, 203, 112, 1);
    background: #f1ab57;
  }

  33% {
    box-shadow: 20px 0 #f1ab57, -20px 0 rgba(255, 203, 112, 1);
    background: rgba(255, 203, 112, 1);
  }

  66% {
    box-shadow: 20px 0 rgba(255, 203, 112, 1), -20px 0 #f1ab57;
    background: rgba(255, 203, 112, 1);
  }

  100% {
    box-shadow: 20px 0 rgba(255, 203, 112, 1), -20px 0 #f1ab57;
    background: #f1ab57;
  }
}




/* payment css */

.payment-table-height {
  height: calc(100vh - 243px);
  background: rgba(192, 192, 192, 0.4);
  border-radius: 20px;
  padding: 10px 10px 10px;
  display: flex;
  max-width: 100%;
  min-height: 100%;
  width: 100%;
}

.payment-table-height::-webkit-scrollbar {
  display: none;
}

.payment-table-height .dashboard-inner {
  background: transparent;
  overflow: hidden;
  overflow-y: scroll;
}

.payment-table-height .dashboard-inner::-webkit-scrollbar {
  display: none;
}


.payment-table .table {
  border-collapse: separate;
}

.payment-table table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-bottom: 0px;
  /* margin-top: -1rem;
  margin-bottom: -1rem; */
}

.payment-table th,
.payment-table td {
  background: #eee;
  padding: 1rem;
  border: 1px solid;
}

.payment-table th+th,
.payment-table td+td {
  border-left: 0;
}

.payment-table tr {
  background-color: #fff9f1;
}

.payment-table td:first-child,
.payment-table th:first-child {
  border-radius: 20px 0 0 20px;
}

.payment-table td:last-child,
.payment-table th:last-child {
  border-radius: 0 20px 20px 0;
}

.v-height-payment-table,
.plan-box {
  height: calc(100vh - 252px);
}

.dashboard-inner .payment-table th {
  background-color: #000;
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
}

.dashboard-inner .payment-table th,
.dashboard-inner .payment-table td {
  background-color: transparent;
  border-bottom-width: 0;
  padding: 15px 0;
  border: 0;
  vertical-align: middle;
  height: 51px;
}

.dashboard-inner .payment-table thead th {
  background-color: #fff;
  padding: 15px 0;
  color: #125049;
  font-weight: 600;
  font-size: 14px;
}

.dashboard-inner .payment-table thead tr {
  border-radius: 20px;
  padding: 15px 0;
}

.dashboard-inner .payment-table thead tr {
  position: sticky;
  top: -10px;
}


.dashboard-inner {
  background: rgba(192, 192, 192, 0.4);
  border-radius: 20px;
  padding: 10px 10px 45px;
  min-width: 100%;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.plan-div {
  background: #fff;
  padding: 10px !important;
}

.plan-div .gradient {
  width: 100%;
  background: #f2bb3f;
  display: inline-block;
  margin-bottom: 20px;
  height: 180px;
}

.plan-div .light {
  width: 100%;
  background: #fff9f1;
  display: inline-block;
  margin-bottom: 20px;
  height: 180px;
}

.plan-div .dark {
  width: 100%;
  background: #3d3c3a;
  display: inline-block;
  margin-bottom: 20px;
  height: 180px;
}

.pricing-card {
  min-height: 420px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}

.pricing-card:hover {
  box-shadow: 0px 0px 20px 2px #ff9f10;
  cursor: pointer;  
}

.pricing-card-active {
  box-shadow: 0px 0px 20px 2px #ff9f10; 
}

.pricing-card-heading {
  padding: 10px;
  /* font-size: 20px; */
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0px;
  min-height: 150px;
}

.pricing-card-heading-free {
  background: rgb(15, 15, 15);
  background: linear-gradient(0deg, rgba(15, 15, 15, 1) 0%, rgba(27, 27, 27, 1) 50%, rgba(35, 35, 35, 1) 100%);
  color: #fff;
  text-align: left;
  -moz-border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  border-radius: 20px 20px 0px 0px;
}

.pricing-card-heading-lite {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgb(52, 27, 27) 0%, rgba(200, 113, 59, 1) 50%, rgba(227, 172, 63, 1) 100%);
  color: #fff;
  text-align: left;
  -moz-border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  border-radius: 20px 20px 0px 0px;
}

.pricing-card-heading-pro {
  background: rgb(236, 230, 223);
  background: linear-gradient(0deg, rgba(236, 230, 223, 1) 0%, rgba(250, 244, 236, 1) 50%, rgb(239 234 226) 100%);
  color: #000;
  text-align: left;
  -moz-border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  border-radius: 20px 20px 0px 0px;
}

.secheading {
  font-size: 19px;
  font-family: "Gloock", serif;
  font-weight: 600;
  font-style: normal;
}

.features-heading {
  font-size: 16px;
  padding: 0px 20px;
  color: #858585;
}

.bg-white.d-block.p-4 {
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  border-radius: 0px 0px 20px 20px;
}

.pricing-card-list {
  text-align: left;
  background: #fff;
  padding: 0px 20px 20px 20px;
  margin: 0;
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  border-radius: 0px 0px 20px 20px;
}

.theme .pricing-card-heading-pro,
.theme .pricing-card-heading-lite,
.theme .pricing-card-heading-free {
  border-radius: 20px;
}

.pricing-card-list-item {
  position: relative;
  list-style: none;
  padding-left: 30px;
  font-size: 16px;
  line-height: 32px;
  color: #898989;
}

.pricing-card-list-item:nth-child(1) {
  position: relative;
  list-style: none;
  /* padding-left: 0px; */
  font-size: 16px;
  line-height: 32px;
  color: #898989;
}

.icon-yes::before {
  position: absolute;
  left: 6px;
  top: 8px;
  display: inline-block;
  content: "";
  transform: rotate(45deg);
  height: 17px;
  width: 9px;
  border-bottom: 3px solid #78b13f;
  border-right: 3px solid #78b13f;
}

.black-text {
  color: #000;
}

.icon-no {
  position: relative;
}

.icon-no::before,
.icon-no:after {
  content: "\f00d";
  position: absolute;
  left: 9px;
  content: " ";
  height: 16px;
  width: 2px;
  background-color: red;
  top: 12px;
}

.icon-no:before {
  transform: rotate(45deg);
}

.icon-no:after {
  transform: rotate(-45deg);
}

.pricing-card-btn:hover {
  background-color: rgb(228, 228, 240);
}

.pricing-card-highlight .pricing-card-btn {
  border: none;
  color: #fff;
  background-color: #27264b;
}

.pricing-card-highlight .pricing-card-btn:hover {
  background-color: #9390db;
}



/* logout btn css */

.logout-icon {
  margin-top: 15px;
  margin-right: 35px;
}
.logout-icon .fa{
  font-size: 20px;
}

@media (max-width: 750px) {
  .logout-icon {
    margin-top: 10px;
    margin-right: 10px;
  }
}
.logout-btn:hover{
  color: #ffffff;
}